<template>
  <div class="material-shop--wrapper">
    <div class="commom_title">
      <a-row type="flex" justify="space-between">
        <a-col :span="8">
          <a-row>
            <a-col :span="2">
              <div class="commom_line"></div>
            </a-col>
            <a-col :span="6">商品详情</a-col>
          </a-row>
        </a-col>
        <!-- <a-col :span="2">
          <a-badge :count="buyCarNumber">
            <a-button @click="handleRouter('/supplies/supplies-car')" class="a-button">
              <a-icon type="shopping-cart" />购物车
            </a-button>
          </a-badge>
        </a-col> -->
      </a-row>
    </div>
    <div class="content">
      <div class="materialShop_detail">
        <a-row>
          <a-col :span="9">
            <div class="materialShop_detail_image">
              <img :src="activeImage" alt />
            </div>
            <a-row>
              <a-col :span="6" v-for="item in goods.pic" :key="item.id">
                <div
                  :class="{
                    materialShop_other_image: true,
                    'materialShop_other_image-active': activeImage === item,
                  }"
                  @click="activeImage = item"
                >
                  <img :src="item" alt />
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="15">
            <div class="materialShop_detail_content">
              <div class="content_header">
                <div class="title">{{ obj.productName }}</div>
              </div>
              <div class="content_detailss" :title="obj.introduction">
                <div class="">商品简介：</div>
                <div class="content_detailss-value">{{ obj.introduction }}</div>
              </div>
              <div class="content_details content_flex">
                <div class="content_item">品牌：{{ obj.brand }}</div>
                <!-- <div class="content_item">库存量：{{ obj.store }}</div> -->
                <!-- <div class="content_item">发货地址： {{ obj.deliverAddress }}</div> -->
              </div>
              <div class="content_details" :title="obj.deliverAddress">发货地址： {{ obj.deliverAddress }}</div>
              <div class="content_details content_flex" v-if="obj.qualityDate">
                <div class="content_item">质保期：{{ obj.qualityDate ? `${obj.qualityDate}天` : '--' }}</div>
              </div>
              <div class="materialShop_detail_line"></div>
              <div class="content_details_shop">
                <div class="shop_label">价格：</div>
                <div class="price">￥{{ goods.price }}</div>
              </div>
              <div class="content_details_shop">
                <div class="shop_label">规格：</div>
                <div class="shop_value">
                  <template v-for="item in obj.specifications">
                    <div
                      :class="{
                        'shop-value-item': true,
                        'shop-value-item-active': gk === item,
                      }"
                      :key="item"
                      @click="handleChangeGK(item)"
                    >
                      {{ item }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="content_details_shop">
                <div class="shop_label">型号：</div>
                <div class="shop_value">
                  <template v-for="item in obj.model">
                    <div
                      :class="{
                        'shop-value-item': true,
                        'shop-value-item-not': !showItem(item, 'model'),
                        'shop-value-item-active': xh === item,
                      }"
                      :key="item"
                      @click="xh = item"
                      v-show="showItem(item, 'model', 'show')"
                    >
                      {{ item }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="shop__number">
                <div class="shop_label">购买数量</div>
                <a-input-number v-model="obj.num" :min="1"></a-input-number>
                <div class="unit">单位：{{ obj.unit }}</div>
              </div>
              <div class="materialShop_detail_line"></div>
              <div class="btn__box" v-if="!!goods.store">
                <a-button type="primary" ghost @click="addCar">加入购物车</a-button>
                <a-button type="primary" @click="Buy">立即购买</a-button>
              </div>
              <div class="btn__box" v-else>
                <a-button type="primary" @click="handleTips">{{ tipText }}</a-button>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <div class="materialShop_detail_msg">
      <a-row :gutter="30">
        <a-col :span="6">
          <div class="materialShop_detail_supplier">
            <div class="materialShop_detail_supplierName">供应商信息</div>
            <div class="materialShop_detail_supplierImage">
              <img :src="employment.logo" alt />
            </div>
            <a-row type="flex" justify="center">
              <a-col :span="8">
                <div class="materialShop_detail_supplierTit">商家名称：</div>
              </a-col>
              <a-col :span="12">
                <div class="materialShop_detail_supplierTxt">{{ employment.supplierName }}</div>
              </a-col>
            </a-row>
            <a-row type="flex" justify="center">
              <a-col :span="8">
                <div class="materialShop_detail_supplierTit">所在地区：</div>
              </a-col>
              <a-col :span="12">
                <div class="materialShop_detail_supplierTxt">
                  {{ employment.province }}-{{ employment.city }}-{{ employment.county }}
                </div>
              </a-col>
            </a-row>
            <a-row type="flex" justify="center">
              <a-col :span="8">
                <div class="materialShop_detail_supplierTit">客服热线：</div>
              </a-col>
              <a-col :span="12">
                <div class="materialShop_detail_supplierTxt">
                  {{ employment.phone }}
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="18">
          <div class="materialShop_shop_detail">
            <div class="materialShop_shop_detailTit">
              <a-row>
                <a-col :span="4">
                  <div :class="[val == 1 ? 'active_materialShop_text' : '']" @click="getItem('1')">商品详情</div>
                </a-col>
                <a-col :span="4">
                  <div :class="[val == 2 ? 'active_materialShop_text' : '']" @click="getItem('2')">商品评价</div>
                </a-col>
              </a-row>
            </div>
            <div v-if="val == 1">
              <a-row>
                <div class="details-context">{{ obj.context }}</div>
                <a-col v-for="item in obj.detailsPics" :key="item">
                  <div class="materialShop_shop_text" style="margin-top: 0">
                    <!-- <img :src="'https://api.employment.ruanwe.com//'+item" alt=""> -->
                    <img :src="item" alt class="img_xj" />
                  </div>
                </a-col>
              </a-row>
            </div>
            <div v-if="val == 2">
              <a-row
                :gutter="24"
                v-for="(item, index) in evaluatelist"
                :key="index"
                style="border-bottom: 1px dashed black"
              >
                <a-col :span="2">
                  <div class="portrait">
                    <img :src="item.image" />
                  </div>
                </a-col>
                <a-col :span="18">
                  <div class="materialShop_shop_name" style="margin-bottom: 5px">{{ item.authorName }}</div>
                  <div style="font-size: 16px">
                    商家服务：<a-rate v-model="item.score1" disabled :tooltips="desc" />
                    <span class="ant-rate-text">{{ desc[item.score1 - 1] }}</span>
                  </div>
                  <div style="font-size: 16px">
                    产品描述：<a-rate v-model="item.score2" disabled :tooltips="desc" />
                    <span class="ant-rate-text">{{ desc[item.score2 - 1] }}</span>
                  </div>
                  <div style="font-size: 16px">
                    物流速度：<a-rate v-model="item.score3" disabled :tooltips="desc" />
                    <span class="ant-rate-text">{{ desc[item.score3 - 1] }}</span>
                  </div>
                  <div v-if="item.tab" style="margin-top: 10px">
                    <span
                      style="font-size: 16px; padding: 0 10px; margin-right: 10px; color: #1677ff"
                      class="text"
                      v-for="(item1, index1) in item.tab.split(',')"
                      :key="index1"
                    >{{ item1 }}</span
                    >
                  </div>
                  <div class="materialShop_shop_text" style="font-size: 16px">{{ item.content }}</div>
                  <div class="div_img">
                    <img :src="item.pic1" alt="" v-if="item.pic1" />
                    <img :src="item.pic2" alt="" v-if="item.pic2" />
                    <img :src="item.pic3" alt="" v-if="item.pic3" />
                    <img :src="item.pic4" alt="" v-if="item.pic4" />
                  </div>
                </a-col>
                <a-col :span="4">
                  <div class="materialShop_shop_rate">
                    {{ formatTime(item.createdTime) }}
                  </div>
                </a-col>
              </a-row>
              <a-row type="flex" justify="end">
                <a-col :span="12">
                  <div class="msg_pagination">
                    <a-pagination show-quick-jumper :default-current="1" :total="totalPages" @change="onChange" />
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-modal title="库存不足提醒" centered v-model="visible" width="550px" :footer="false">
      <div style="text-align: center; color: #3d3d3d">
        该规格型号下库存为0，无法购买及保存到购物车！可点击“提醒供应商补货”按钮，提醒供应商添加库存。
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  addenterpriseCartController,
  employmentEvaluate,
  employmentMall,
  enterpriseProductpageByDetail,
  employmentMallList,
  sendBuyMsg
} from '@/api/materialShop'
import moment from 'moment'

export default {
  data () {
    return {
      desc: ['非常不满意', '不满意', '一般', '满意', '非常满意'],
      obj: {},
      val: 1,
      buyCarNumber: 0,
      value: 4,
      queryParams: {
        pageIndex: 1,
        pageSize: 10,
        productId: this.$route.query.id
      },
      employment: {},
      totalPages: '',
      evaluatelist: [],
      gk: '',
      xh: '',
      activeImage: '',
      visible: false,
      tipText: '提醒供应商补货'
    }
  },
  inject: ['reload'],
  mounted () {
    this.init()
  },
  computed: {
    goods () {
      if (this.gk && this.xh) {
        const data = this.obj.productSkuDtoList.find(
          (item) => item.specifications === this.gk && item.model === this.xh
        )
        if (data && !!data.store) {
          const pic = data.pic || this.obj.pic
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.activeImage = pic.split(',')[0]
          return {
            ...data,
            pic: pic.split(',')
          }
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.activeImage = this.obj.pic
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.visible = true
          return {
            pic: [this.obj.pic],
            price: data.price || 0
          }
        }
      }
      return {
        pic: []
      }
    }
  },
  methods: {
    formatTime (t) {
      return t ? moment(t).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    handleRouter (path) {
      if (this.handleIsLogin()) {
        this.$router.push(path)
      }
    },
    Buy () {
      if (this.handleIsLogin()) {
        const checkList = []
        checkList.push(this.employment)
        checkList.forEach((v) => {
          v.products = []
          v.products.push({
            ...this.obj,
            specifications: this.gk,
            model: this.xh,
            productId: this.goods.id,
            price: this.goods.price
          })
        })
        this.reload()
        this.$router.push({
          path: '/supplies/supplies-order',
          query: { totalPrice: this.accMul(this.goods.price, this.obj.num), data: JSON.stringify(checkList) }
        })
      }
    },
    getImage (val) {
      this.obj.pic = val
    },
    handleGetBuyNumber () {
      if (this.isLogin) {
        employmentMallList().then((res) => {
          this.buyCarNumber = res.data.reduce((number, item) => {
            return (number += item.products.length)
          }, 0)
        })
      }
    },
    init () {
      this.handleGetBuyNumber()
      const params = {
        id: this.$route.query.id
      }
      enterpriseProductpageByDetail(params)
        .then((res) => {
          const productSkuDtoList = res.data.productSkuDtoList.sort((a, b) => a.sort - b.sort)
          this.obj = {
            ...res.data,
            specifications: Array.from(new Set(productSkuDtoList.map((item) => item.specifications))),
            model: Array.from(new Set(productSkuDtoList.map((item) => item.model)))
          }
          this.$set(this.obj, 'num', 1)
          const data = productSkuDtoList.find((item) => !!item.store)
          this.gk = data.specifications || this.obj.specifications[0]
          this.xh = data.model || this.obj.model[0]
          const businessId = {
            id: this.obj.businessId
          }
          employmentMall(businessId)
            .then((res) => {
              this.employment = res.data
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
        })
      employmentEvaluate(this.queryParams)
        .then((res) => {
          this.evaluatelist = res.data
          this.totalPages = res.totalPages
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onChange (pageNumber) {
      this.evaluatelist = []
      this.queryParams.pageIndex = pageNumber
      employmentEvaluate(this.queryParams)
        .then((res) => {
          this.evaluatelist = res.data
          this.totalPages = res.totalPages
        })
        .catch((err) => {
          console.log(err)
        })
    },
    addCar () {
      if (this.handleIsLogin()) {
        addenterpriseCartController({
          ...this.obj,
          pic: this.goods.pic.join(','),
          supplierId: this.obj.businessId,
          productId: this.goods.id
        })
          .then((res) => {
            if (res.success) {
              this.$message.success('正在购物车等您!')
              this.$eventBus.$emit('handleAddCar')
              // this.handleGetBuyNumber()
              // this.$router.push('/materialShop/materialShopCar')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    handleTips () {
      if (!this.handleIsLogin()) {
        return
      }
      if (this.tipText === '提醒供应商补货') {
        this.tipText = '已提醒供应商'
        sendBuyMsg({
          businessId: this.obj.businessId,
          model: this.xh,
          specifications: this.gk,
          productName: this.obj.productName
        }).then((res) => {
          this.$message.success('已提醒供应商补充库存！')
        })
      }
    },
    getItem (val) {
      this.val = val
    },
    showItem (data, type, ifShow) {
      const key = type === 'model' ? 'gk' : 'xh'
      const itemKey = type === 'model' ? 'specifications' : 'model'
      const newData = this.obj.productSkuDtoList.find((item) => item[type] === data && item[itemKey] === this[key])
      if (ifShow) {
        return !!newData
      } else {
        if (!newData) return false
        return !!newData.store
      }
    },
    handleChangeGK (gk) {
      const dataList = this.obj.productSkuDtoList.filter((item) => item.specifications === gk)
      if (!dataList.some((item) => item.model === this.xh)) {
        const data = dataList.find((item) => !!item.store)
        if (data) {
          this.xh = data.model
        } else {
          this.xh = dataList[0].model
        }
      }
      this.gk = gk
    }
  }
}
</script>
<style lang="less">
@import url('../../../common/style');
@import url('../search/index');
.material-shop--wrapper {
  .row__item {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="less" scoped>
.div_img {
  margin-bottom: 10px;
  img {
    margin-right: 15px;
    width: 150px;
    height: 150px;
  }
}
//input购买数量专用
.input_num {
  width: 70px;
  height: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  display: inline-block;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
//购物车样式
.a-button {
  color: #1890ff;
  font-size: 16px;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
//图片细节
.img_xj {
  width: 750px;
  height: 100%;
  display: block;
  margin: 0 auto;
}
.details-context {
  width: 750px;
  margin: 20px auto;
}
.portrait {
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
<style lang="less">
.material-shop--wrapper {
  .materialShop_detail_content {
    font-size: 18px;
    width: 100%;
    .content_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      .title {
        font-weight: 600;
        color: #222222;
        font-size: 28px;
      }
      .price {
        font-size: 20px;
        color: red;
        font-weight: 600;
      }
    }
    .content_details {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 15px;
      display: flex;
      .content_item {
      }
    }
    .content_detailss {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      &-value {
        width: calc(100% - 90px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .content_details_shop {
      display: flex;
      margin-bottom: 15px;
      align-items: baseline;
      .shop_label {
        color: #222222;
        font-weight: 550;
        width: 100px;
      }
      .shop_value {
        flex: 1;
        .shop-value-item {
          cursor: pointer;
          padding: 0 25px;
          width: auto;
          height: 36px;
          border-radius: 2px;
          background: #eeeeee;
          color: #333333;
          font-size: 16px;
          display: inline-block;
          line-height: 36px;
          margin: 0 20px 20px 0;
          border: 1px solid #eeeeee;
          position: relative;
          &-not {
            color: #dddddd;
            background: #ffffff;
            border: 1px solid #999999;
            &::after {
              content: '缺货';
              font-size: 10px;
              position: absolute;
              top: -7px;
              right: 5px;
              height: 14px;
              line-height: 14px;
              padding: 0 5px;
              background: #ffffff;
              color: #999999;
            }
          }
          &-active {
            background: rgba(22, 119, 255, 0.1);
            border: 1px solid #1677ff;
            color: #1677ff;
          }
        }
      }
      .price {
        flex: 1;
        font-size: 24px;
        font-weight: 550;
        color: #ff5555;
        padding: 3px 10px;
      }
    }
    .shop__number {
      display: flex;
      align-items: center;
      .shop_label {
        color: #222222;
        font-weight: 550;
        width: 100px;
      }
      .unit {
        font-size: 16px;
        color: #999999;
        margin-left: 10px;
      }
    }
    .btn__box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .ant-btn {
        margin-left: 10px;
      }
    }
    .content_flex {
      justify-content: space-between;
      display: flex;

      .content_item {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.text {
  height: 36px;
  background: rgba(22, 119, 255, 0.22);
  border-radius: 14px;
}
</style>
